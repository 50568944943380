<template>
  <div>产品类型</div>
</template>

<script>
export default {
  name: 'productType',
  data () {
    return {}
  }
}
</script>

<style>
</style>
